import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "gatsby"
import Cursor from './Cursor';

export const FeaturedProjects = ({ featuredProjects }) => {

  return (
    <Container fluid className='pt-4 pt-xl-3 pt-xxl-0'>
      <Row className='border-top py-3 d-flex flex-row align-items-center'>
        <Col xs={10} className='d-flex flex-column flex-sm-row'>
          <Col as="h3" className='m-0'>Featured work</Col>
          <Col as="h3" className='m-0'>2005 - {new Date().getFullYear()}</Col>
        </Col>
        <Col as="h3" xs={2} className='text-end'><Link to="/projects">ALL<Cursor /></Link></Col>
      </Row>
      <div className='pb-2 pb-sm-3'>
        {featuredProjects.map((project, i) => {
          return (
            <Row key={i} data-aos="fade-up">
              <Link to={`/projects/${project.uid}`} className="featured-work-project d-flex flex-column flex-md-row  py-3 py-md-5" >
                <Col md={6} xl={5}>
                  <div className="featured-project-details">
                    <h1 className='mb-4 mb-lg-5'>{project.data.project_title}<Cursor /></h1>
                    <div className='featured-project-year-desc d-flex flex-row d-none d-md-flex'>
                      <span className='me-3 me-lg-4'><h2>{project.data.project_year}</h2></span>
                      <h6 className='pe-4 pe-xl-5'>{project.data.featured_text}</h6>
                    </div>
                  </div>

                </Col>
                <Col className='featuredImageWrapper mb-3' md={6} xl={7}>
                  {project.data.project_main_image.gatsbyImageData &&
                    <GatsbyImage
                      className='p-0'
                      image={project.data.project_main_image.gatsbyImageData}
                      alt={project.data.project_main_image.alt || "project image"}
                    />}
                </Col>
                <Col className="mobileDetails d-block d-md-none mb-3">
                  <div className='featured-project-year-desc d-flex my-3 flex-row '>
                    <span><h2 className='me-3'>{project.data.project_year}</h2></span>
                    <h6>{project.data.featured_text}</h6>
                  </div>
                </Col>
              </Link>

            </Row>
          )
        })}

      </div>
      <div className='secondBorderRow'></div>
    </Container>
  )
}

export default FeaturedProjects;