import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import Cursor from './Cursor';

export const ProjectCategories = () => {
  return (
    <Container fluid className='pt-4'>
      <div className='d-flex justify-content-between pb-4'>
        <span><h3>BROWSE BY CATEGORY</h3></span>
        <span> <h3><Link to="/projects">ALL<Cursor /></Link></h3> </span>
      </div>
      <Row className='browseByCategory justify-content-between pb-4'>
        <Col md={3} className="square bg-dark text-light" data-aos="fade-up">
          <Link to='/projects?category=bakery-coffee-deli' className='text-light'>
            <div className='content'>
              <div className='category-image-wrapper'>
                <StaticImage
                  src="../images/shapes/Rectangle 6.png"
                  alt="rectangle"
                  className="categoryShape"
                />
                <StaticImage
                  src="../images/shapes/Group 8.png"
                  alt="rectangle"
                  className="categoryLogo"
                />
              </div>
              <span className='category-name-wrapper pe-4'>
                <h3>Bakery, coffee & deli</h3>
              </span>
            </div>
          </Link>
        </Col>
        <Col md={3} className="square bg-dark text-light" data-aos="fade-up">
          <Link to='/projects?category=fb-projects' className='text-light'>
            <div className='content'>
              <div className='category-image-wrapper'>
                <StaticImage
                  src="../images/shapes/Polygon 1.png"
                  alt="rectangle"
                  className="categoryShape"
                />
                <StaticImage
                  src="../images/shapes/Group 8.png"
                  alt="rectangle"
                  className="categoryLogo"
                />
              </div>
              <span className='category-name-wrapper pe-4'>
                <h3>F&B Projects</h3>
              </span>
            </div>
          </Link>
        </Col>
        <Col md={3} className="square bg-dark text-light" data-aos="fade-up">
          <Link to='/projects?category=fashion-commercial' className='text-light'>
            <div className='content'>
              <div className='category-image-wrapper'>
                <StaticImage
                  src="../images/shapes/Ellipse 1.png"
                  alt="rectangle"
                  className="categoryShape"
                />
                <StaticImage
                  src="../images/shapes/Group 8.png"
                  alt="rectangle"
                  className="categoryLogo"
                />
              </div>
              <span className='category-name-wrapper pe-4'>
                <h3>Fashion & commercial</h3>
              </span>
            </div>
          </Link>
        </Col>
        <Col md={3} className="square bg-dark text-light" data-aos="fade-up">
          <Link to='/projects?category=hospitality-projects' className='text-light'>
            <div className='content'>
              <div className='category-image-wrapper'>
                <StaticImage
                  src="../images/shapes/Rectangle 7.png"
                  alt="rectangle"
                  className="categoryShape"
                />
                <StaticImage
                  src="../images/shapes/Group 8.png"
                  alt="rectangle"
                  className="categoryLogo"
                />
              </div>
              <span className='category-name-wrapper pe-4'>
                <h3>Hospitality Projects</h3>
              </span>
            </div>
          </Link>
        </Col>
      </Row>
      <div className='secondBorderRow'></div>
    </Container>
  )
}

export default ProjectCategories;