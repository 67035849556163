import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FeaturedProjects from "../components/featuredProjects"
import ProjectCategories from "../components/projctCategories"
import BrowseArticles from "../components/browseArticles"
import AOS from 'aos';
import 'aos/dist/aos.css';

export const query = graphql`
  {
    allPrismicProject(filter: {data: {is_featured: {eq: true}}}) {
      nodes {
        uid
        type
        data {
          project_year
          project_title
          featured_text
          project_main_image {
            url
            gatsbyImageData
            alt
          }
        }
        tags
        id
      }
    }
    allPrismicNewsPress(
      sort: {order: DESC, fields: data___date}
      filter: {data: {is_featured: {eq: true}}}
    ) {
        nodes {
          uid
          tags
          data {
            date
            category
            title
          }
        }
    }
  }
`

const IndexPage = (props) => {

  useEffect(() => {
    AOS.init({
      offset: -10,
      delay: 50,
      duration: 800,
      easing: 'ease',
      mirror: true
    });
  }, [])

  return (
    <Layout location={props.path}>
      <Seo title="Kondylis NYC" />
      <FeaturedProjects featuredProjects={props.data.allPrismicProject.nodes} />
      <ProjectCategories />
      <div className="browseFeaturedArticles">
        <BrowseArticles articles={props.data.allPrismicNewsPress.nodes} />
      </div>

    </Layout>
  )
}

export default IndexPage
